<template>
    <BaseCard
        :entry="entry"
        :is-inline="isInline"
        class="dossier-card"
        :data-is-following="userIsFollowing"
    >
        <template #meta>
            <FenexTag v-if="entry.isFenex" />

            <BaseTag>Dossier</BaseTag>
        </template>
    </BaseCard>
</template>

<script setup>
import BaseCard from '@/components/BaseCard/BaseCard.vue';
import BaseTag from '@/components/BaseTag/BaseTag.vue';
import { useAuthStore } from '~/store/auth';
import { computed } from 'vue';

const props = defineProps({
    entry: {
        type: Object,
        required: true,
        default: null
    },
    isInline: {
        type: Boolean,
        default: false
    }
});

const user = computed(() => {
    return useAuthStore().userModel;
});

const userIsFollowing = computed(() => {
    if (!user.value) {
        return false;
    }

    return toValue(user).isFollowingDossier([props.entry.id]);
});
</script>

<style lang="less" src="./DossierCard.less" />
